const paragraph = document.getElementById('animatedParagraph');
const words = paragraph.textContent.split(' ');
paragraph.innerHTML = words.map(word => `<span>${word}</span>`).join(' ');

const spans = paragraph.querySelectorAll('span');
let currentIndex = 0;

function animateNextWord() {
  if (currentIndex < spans.length) {
    spans[currentIndex].style.color = `#7466e9`;
    currentIndex++;
    setTimeout(animateNextWord, 200);
  } else {
    // 5-second pause before resetting the colors and restarting the animation
    setTimeout(() => {
      reverseAnimation(spans.length - 1);
    }, 5000); // 5-second pause
  }
}

function reverseAnimation(index) {
  if (index >= 0) {
    spans[index].style.color = '';
    setTimeout(() => reverseAnimation(index - 1), 100); // Faster reverse animation
  } else {
    currentIndex = 0;
    setTimeout(animateNextWord, 100); // Restart the animation
  }
}

const observer = new IntersectionObserver((entries) => {
  entries.forEach(entry => {
    if (entry.isIntersecting) {
      setTimeout(animateNextWord, 1000); // 1-second delay before starting the animation
      observer.unobserve(paragraph);
    }
  });
}, { threshold: 0.1 });

observer.observe(paragraph);
