// Function to handle the progress animation
function animateProgress(progressElement) {
  // Reapply the transition before starting the animation
  progressElement.style.transition = 'transform 2s linear, border-color 0.5s';

  // Change the border color to the CSS variable and rotate the border from left to right
  progressElement.style.borderColor = 'var(--wp--preset--color--brand)'; // Border becomes the color of the CSS variable
  progressElement.style.transform = 'rotate(0deg)'; // Rotate from left to right

  // Add the .active class to the wrapper after a 1-second delay
  setTimeout(() => {
    const progressBackground = document.querySelector('.sales-progression');
    if (progressBackground) {
      progressBackground.classList.add('active');
    }
  }, 2000); // 2-second delay
}

// Function to reset the animation
function resetProgress(progressElement) {
  // Remove the transition to reset instantly
  progressElement.style.transition = 'none';
  progressElement.style.borderColor = 'white'; // Border becomes blue again
  progressElement.style.transform = 'rotate(-180deg)'; // Return to the starting position

  // Remove the .active class from the wrapper
  const progressBackground = document.querySelector('.sales-progression');
  if (progressBackground) {
    progressBackground.classList.remove('active');
  }
}

// Main function to trigger the animation on page load and replay it every 20 seconds
function startProgressAnimation() {
  const progressElement = document.querySelector('.progress');

  // Start the animation immediately
  animateProgress(progressElement);

  // Reset and replay the animation every 20 seconds
  setInterval(() => {
    resetProgress(progressElement); // Reset the bar
    setTimeout(() => {
      animateProgress(progressElement); // Restart the animation after the reset
    }, 100); // Slight pause to ensure the reset is visually instantaneous
  }, 6000); // Every XX seconds, reset and replay the animation
}

// Call the function on page load
window.onload = startProgressAnimation;
